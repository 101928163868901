import { createApp } from 'vue';
import App from './App.vue';
import VueGtag from 'vue-gtag';

// Crear la aplicación Vue
const app = createApp(App);

// Configurar Google Analytics con vue-gtag
app.use(VueGtag, {
  config: { id: 'G-KCV37Z737L' }, // Reemplaza con tu Measurement ID
});

// Montar la aplicación
app.mount('#app');
